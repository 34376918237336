import { render, staticRenderFns } from "./ContractDetailsHistory.vue?vue&type=template&id=4845bd62&scoped=true"
import script from "./ContractDetailsHistory.vue?vue&type=script&lang=js"
export * from "./ContractDetailsHistory.vue?vue&type=script&lang=js"
import style0 from "./ContractDetailsHistory.vue?vue&type=style&index=0&id=4845bd62&prod&lang=scss&scoped=true"
import style1 from "./ContractDetailsHistory.vue?vue&type=style&index=1&id=4845bd62&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4845bd62",
  null
  
)

export default component.exports